import axios, { AxiosRequestConfig } from 'axios';
import { getShowroomUrl } from '../services/getShowroomUrl';
import IResponse from '../interfaces/IResponse';
import { IFurniture } from '../interfaces/IFurniture';
import ApiHelper from './ApiHelper';

const BASE_URL = `${getShowroomUrl()}api/furniture`;

interface IGetAllFurniture extends IResponse {
    furniture: IFurniture[] | null,
}

const getAllFurniture = async (): Promise<IGetAllFurniture> => {

    const url = BASE_URL;

    try {
        const res = await axios.get(url);

        const resoult = {
            message: 'Pobrano meble',
            status: res.status,
            furniture: res.data.furniture,
            ok: true,
        }

        resoult.furniture.reverse()

        return resoult;
    } catch (error) {
        return {
            message: 'Nie pobrano mebli',
            status: 400,
            furniture: null,
            ok: false,
        };
    }
}

const getAllAdminFurniture = async (): Promise<IGetAllFurniture> => {

    const url = `${BASE_URL}/admin`;

    try {
        const res = await axios.get(url);

        const resoult = {
            message: 'Pobrano meble',
            status: res.status,
            furniture: res.data.furniture,
            ok: true,
        }

        resoult.furniture.reverse()

        return resoult;
    } catch (error) {
        return {
            message: 'Nie pobrano mebli',
            status: 400,
            furniture: null,
            ok: false,
        };
    }
}

interface IGetPieceOfFurniture extends IResponse {
    furniture: IFurniture | null
}

const getPieceOfFurniture = async (_id: string | null): Promise<IGetPieceOfFurniture> => {

    const url = `${BASE_URL}/${_id}`

    try {
        const res = await axios.get(url);

        const resoult = {
            furniture: res.data.furniture,
            message: 'Pobrano informacje o meblu',
            ok: true,
            status: res.status,
        }

        return resoult

    } catch (error) {
        let message = 'Nie udało się pobrać infmacji o meblu'
        let status = 400;

        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 403) message = 'Nie można pobrać mebla'
            if (error.response.status) status = error.response.status;
        }

        return {
            message,
            status,
            ok: false,
            furniture: null,
        }
    }
}

interface IAddFurniture extends IResponse {
    newFurniture: IFurniture | null,
}

export interface IAddFurnitureDto {
    id?: number,
    name: string,
    producer: string,
    partCollection: string | null,
    price: number | null,
    width: number | null,
    depth: number | null,
    height: number | null,
    crossed: number | null,
    isPriceVissible: boolean,
    description: string | null,
    images: any[],
    premises: string[],
    furnitureCategories: string[],
    randomKey?: string,
    onAddFurniture?: (furniture: IFurniture) => void,
}

const addFurniture = async (furniture: IAddFurnitureDto): Promise<IAddFurniture> => {
    const url = BASE_URL;
    const formData = new FormData();

    formData.append('name', furniture.name);
    formData.append('producer', furniture.producer);
    formData.append('partCollection', furniture.partCollection ? furniture.partCollection : '');
    formData.append('price', furniture.price ? furniture.price.toString() : '');
    formData.append('width', furniture.width ? furniture.width.toString() : '');
    formData.append('depth', furniture.depth ? furniture.depth.toString() : '');
    formData.append('height', furniture.height ? furniture.height.toString() : '');
    formData.append('crossed', furniture.crossed ? furniture.crossed.toString() : '');
    formData.append('isPriceVissible', furniture.isPriceVissible.toString());
    formData.append('description', furniture.description ? furniture.description : '');
    furniture.premises.forEach((premise, index) => {
        formData.append(`premises[${index}]`, premise);
    });
    furniture.furnitureCategories.forEach((category, index) => {
        formData.append(`furnitureCategories[${index}]`, category);
    });

    furniture.images.forEach((image, index) => {
        formData.append('images', image);
    });
    

    const addFurnitureConfig: AxiosRequestConfig = {
        headers: ApiHelper.createHeaderForFiles(),
    }

    try {
        const res = await axios.post(url, formData, addFurnitureConfig);

        const resoult = {
            newFurniture: res.data.newFurniture,
            message: 'Mebel dodany',
            ok: true,
            status: res.status,
        }

        return resoult;
    } catch (error) {
        
        let message = 'Nie udalo się dodać mebla';
        let status = 400;

        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 403) message = 'Nie można dodać mebla';
            if (error.response.status) status = error.response.status;
        }

        return {
            newFurniture: null,
            message,
            ok: false,
            status,
        };
    }
};


interface IEditFurniture extends IResponse {
    editedFurniture: IFurniture | null,
}

export interface IEditFurnitureDto {
    _id: string,
    name: string,
    producer: string,
    partCollection: string | null,
    price: number | null,
    width: number | null,
    depth: number | null,
    height: number | null,
    crossed: number | null,
    isPriceVissible: boolean,
    designedForTheLivingRoom: boolean,
    designedForTheKitchen: boolean,
    designedForTheBedroom: boolean,
    designedForTheOffice: boolean,
    designedForTheYouthRoom: boolean,
    designedForTheHallway: boolean,
    designedForTheChildrensRoom: boolean,
    designedForTheBathroom: boolean,
    categories: string | null,
    image: File,
    description: string | null,
}

const editFurniture = async (furniture: IEditFurnitureDto): Promise<IEditFurniture> => {

    const url = `${BASE_URL}/${furniture._id}`
    const editFurnitureConfig: AxiosRequestConfig = {
        headers: ApiHelper.createHeaderForFiles(),
    }

    try {
        const res = await axios.put(url, furniture, editFurnitureConfig)

        const resoult = {
            editedFurniture: res.data.editedFurniture,
            message: 'Mebel edytowany',
            ok: true,
            status: res.status,
        }

        return resoult
    } catch (error) {
        let message = 'Nie udało się edytować mebla'
        let status = 400

        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 403) message = 'Nie można edytować mebla'
            if (error.response.status) status = error.response.status
        }

        return {
            message,
            status,
            ok: false,
            editedFurniture: null,
        }
    }
}

interface IDeleteFurniture extends IResponse { }

const deleteFurniture = async (_id: string): Promise<IDeleteFurniture> => {

    const url = `${BASE_URL}/${_id}`;
    const config: AxiosRequestConfig = {
        headers: ApiHelper.createHeader(),
    }

    try {
        const res = await axios.delete(url, config);

        const resoult = {
            ok: true,
            status: res.status,
            message: 'Usunięto mebel'
        }

        return resoult
    } catch (error) {

        let message = 'Nie usunięto mebla'
        let status = 400

        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 404) message = 'Nie znaleziono mebla'
            if (error.response.status === 403) message = 'Nie możesz tego zrobić'
            if (error.response.status) status = error.response.status
        }

        return {
            message,
            status,
            ok: false,
        }
    }
}

interface IAddImagesToSingleFurniture extends IResponse {
    newFurniture: IFurniture | null,
}

const addImagesToSingleFurniture = async (furnitureId: string, images: any[]): Promise<IAddImagesToSingleFurniture> => {

    const url = `${BASE_URL}/addImages/${furnitureId}`;
    const formData = new FormData();

    images.forEach((image, index) => {
        formData.append('images', image);
    });

    const config: AxiosRequestConfig = {
        headers: ApiHelper.createHeaderForFiles(),
    }

    try {
        const res = await axios.post(url, formData, config);

        const resoult = {
            newFurniture: res.data.newFurniture,
            message: 'Dodano zdjęcia',
            ok: true,
            status: res.status,
        }

        return resoult;
    } catch (error) {
        let message = 'Nie dodano zdjęć'
        let status = 400

        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 404) message = 'Nie znaleziono mebla'
            if (error.response.status === 403) message = 'Nie można dodać zdjęć'
            if (error.response.status) status = error.response.status
        }

        return {
            message,
            status,
            ok: false,
            newFurniture: null,
        }
    }
}

interface IDeleteImageFromFurniture extends IResponse {
}

const deleteImageFromFurniture = async (furnitureId: string, imagePath: string): Promise<IDeleteImageFromFurniture> => {
    const url = `${BASE_URL}/deleteImage/${furnitureId}`;
    const config: AxiosRequestConfig = {
        headers: ApiHelper.createHeader()
    }

    const data = new FormData();
    data.append('imagePath', imagePath);

    try {
        const res = await axios.patch(url, data, config);

        const resoult = {
            message: 'Usunięto zdjęcie',
            status: res.status,
            ok: true,
        }

        return resoult;
    } catch (error) {

        let message = 'Nie usunięto zdjęcia';
        let status = 400;

        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 404) message = 'Nie znaleziono mebla';
            if (error.response.status === 403) message = 'Nie można usunąć zdjęcia';
            if (error.response.status) status = error.response.status;
        }

        return {
            message,
            status,
            ok: false,
        };
    }
}


interface IMoveFurnitureImage extends IResponse {
    newFurniture: IFurniture | null,
}

const moveFurnitureImage = async (furnitureId: string, imagePath: string, direction: 'up' | 'down'): Promise<IMoveFurnitureImage> => {
    const url = `${BASE_URL}/moveImage/${furnitureId}`;
    const config: AxiosRequestConfig = {
        headers: ApiHelper.createHeader(),
    }

    const data = new FormData();
    data.append('imagePath', imagePath);
    data.append('direction', direction);

    try {
        const res = await axios.patch(url, data, config);

        const resoult = {
            message: 'Przesunięto zdjęcie',
            status: res.status,
            ok: true,
            newFurniture: res.data.newFurniture,
        }

        return resoult;
    } catch (error) {
        let message = 'Nie przesunięto zdjęcia';
        let status = 400;

        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 404) message = 'Nie znaleziono mebla';
            if (error.response.status === 403) message = 'Nie można przesunąć zdjęcia';
            if (error.response.status) status = error.response.status;
        }

        return {
            message,
            status,
            ok: false,
            newFurniture: null,
        }
    }
}


interface IEditFurnitureName extends IResponse {
    editedFurniture: IFurniture | null,
}

const editFurnitureName = async (furnitureId: string, newName: string): Promise<IEditFurnitureName> => {
    const url = `${BASE_URL}/editName/${furnitureId}`;
    const config: AxiosRequestConfig = {
        headers: ApiHelper.createHeader(),
    }

    const data = new FormData();
    data.append('name', newName);

    try {
        const res = await axios.patch(url, data, config);

        const resoult = {
            message: 'Zmieniono nazwę',
            status: res.status,
            ok: true,
            editedFurniture: res.data.editedFurniture,
        }

        return resoult;
    } catch (error) {
        let message = 'Nie zmieniono nazwy';
        let status = 400;

        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 404) message = 'Nie znaleziono mebla';
            if (error.response.status === 403) message = 'Nie można zmienić nazwy';
            if (error.response.status) status = error.response.status;
        }

        return {
            message,
            status,
            ok: false,
            editedFurniture: null,
        }
    }
}


interface IEditDimension extends IResponse {
    editedFurniture: IFurniture | null,
}

export interface IEditDimensionDto {
    furnitureId: string,
    dimensionName: 'width' | 'height' | 'depth',
    value: number,
}

const editDimension = async (furnitureData: IEditDimensionDto): Promise<IEditDimension> => {
    const url = `${BASE_URL}/editDimension/${furnitureData.furnitureId}`;
    const config: AxiosRequestConfig = {
        headers: ApiHelper.createHeader(),
    }

    const data = new FormData();
    data.append('dimensionName', furnitureData.dimensionName);
    data.append('value', furnitureData.value.toString());

    try {
        const res = await axios.patch(url, data, config);

        const resoult = {
            message: 'Zmieniono wymiar',
            status: res.status,
            ok: true,
            editedFurniture: res.data.editedFurniture,
        }

        return resoult;
    } catch (error) {
        let message = 'Nie zmieniono wymiaru';
        let status = 400;

        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 404) message = 'Nie znaleziono mebla';
            if (error.response.status === 403) message = 'Nie można zmienić wymiaru';
            if (error.response.status) status = error.response.status;
        }

        return {
            message,
            status,
            ok: false,
            editedFurniture: null,
        }
    }
}


interface IEditPrice extends IResponse {
    editedFurniture: IFurniture | null,
}

export interface IEditPriceDto {
    furnitureId: string,
    priceType: 'price' | 'crossedPrice',
    value: number,
}

const editPrice = async (furnitureData: IEditPriceDto): Promise<IEditPrice> => {
    const url = `${BASE_URL}/editPrice/${furnitureData.furnitureId}`;
    const config: AxiosRequestConfig = {
        headers: ApiHelper.createHeader(),
    }

    const data = new FormData();
    data.append('priceType', furnitureData.priceType);
    data.append('value', furnitureData.value.toString());

    try {
        const res = await axios.patch(url, data, config);

        const resoult = {
            message: 'Zmieniono cenę',
            status: res.status,
            ok: true,
            editedFurniture: res.data.editedFurniture,
        }

        return resoult;
    } catch (error) {
        let message = 'Nie zmieniono ceny';
        let status = 400;

        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 404) message = 'Nie znaleziono mebla';
            if (error.response.status === 403) message = 'Nie można zmienić ceny';
            if (error.response.status) status = error.response.status;
        }

        return {
            message,
            status,
            ok: false,
            editedFurniture: null,
        }
    }
}


interface IChangePriceVisibility extends IResponse {
    editedFurniture: IFurniture | null,
}

export interface IChangePriceVisibilityDto {
    furnitureId: string,
    isPriceVissible: boolean,
}

const changePriceVisibility = async (furnitureData: IChangePriceVisibilityDto): Promise<IChangePriceVisibility> => {
    const url = `${BASE_URL}/changePriceVisibility/${furnitureData.furnitureId}`;
    const config: AxiosRequestConfig = {
        headers: ApiHelper.createHeader(),
    }

    const data = new FormData();
    data.append('isPriceVissible', furnitureData.isPriceVissible.toString());

    try {
        const res = await axios.patch(url, data, config);

        const resoult = {
            message: 'Zmieniono widoczność ceny',
            status: res.status,
            ok: true,
            editedFurniture: res.data.editedFurniture,
        }

        return resoult;
    } catch (error) {
        let message = 'Nie zmieniono widoczności ceny';
        let status = 400;

        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 404) message = 'Nie znaleziono mebla';
            if (error.response.status === 403) message = 'Nie można zmienić widoczności ceny';
            if (error.response.status) status = error.response.status;
        }

        return {
            message,
            status,
            ok: false,
            editedFurniture: null,
        }
    }
}


interface IEditDescription extends IResponse {
    editedFurniture: IFurniture | null,
}

export interface IEditDescriptionDto {
    furnitureId: string,
    description: string,
}

const editDescription = async (furnitureData: IEditDescriptionDto): Promise<IEditDescription> => {
    const url = `${BASE_URL}/editDescription/${furnitureData.furnitureId}`;
    const config: AxiosRequestConfig = {
        headers: ApiHelper.createHeader(),
    }

    const data = new FormData();
    data.append('description', furnitureData.description);

    try {
        const res = await axios.patch(url, data, config);

        const resoult = {
            message: 'Zmieniono opis',
            status: res.status,
            ok: true,
            editedFurniture: res.data.editedFurniture,
        }

        return resoult;
    } catch (error) {
        let message = 'Nie zmieniono opisu';
        let status = 400;

        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 404) message = 'Nie znaleziono mebla';
            if (error.response.status === 403) message = 'Nie można zmienić opisu';
            if (error.response.status) status = error.response.status;
        }

        return {
            message,
            status,
            ok: false,
            editedFurniture: null,
        }
    }
}


interface IEditProducer extends IResponse {
    editedFurniture: IFurniture | null,
}

export interface IEditProducerDto {
    furnitureId: string,
    producerId: string,
}

const editProducer = async (furnitureData: IEditProducerDto): Promise<IEditProducer> => {
    const url = `${BASE_URL}/editProducer/${furnitureData.furnitureId}`;
    const config: AxiosRequestConfig = {
        headers: ApiHelper.createHeader(),
    }

    const data = new FormData();
    data.append('producer', furnitureData.producerId);

    try {
        const res = await axios.patch(url, data, config);

        const resoult = {
            message: 'Zmieniono producenta',
            status: res.status,
            ok: true,
            editedFurniture: res.data.editedFurniture,
        }

        return resoult;
    } catch (error) {
        let message = 'Nie zmieniono producenta';
        let status = 400;

        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 404) message = 'Nie znaleziono mebla';
            if (error.response.status === 403) message = 'Nie można zmienić producenta';
            if (error.response.status) status = error.response.status;
        }

        return {
            message,
            status,
            ok: false,
            editedFurniture: null,
        }
    }
}


interface IEditCollection extends IResponse {
    editedFurniture: IFurniture | null,
}

export interface IEditCollectionDto {
    furnitureId: string,
    collectionId: string,
}

const editCollection = async (furnitureData: IEditCollectionDto): Promise<IEditCollection> => {
    const url = `${BASE_URL}/editCollection/${furnitureData.furnitureId}`;
    const config: AxiosRequestConfig = {
        headers: ApiHelper.createHeader(),
    }

    const data = new FormData();
    data.append('collection', furnitureData.collectionId);

    try {
        const res = await axios.patch(url, data, config);

        const resoult = {
            message: 'Zmieniono kolekcję',
            status: res.status,
            ok: true,
            editedFurniture: res.data.editedFurniture,
        }

        return resoult;
    } catch (error) {
        let message = 'Nie zmieniono kolekcji';
        let status = 400;

        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 404) message = 'Nie znaleziono mebla';
            if (error.response.status === 403) message = 'Nie można zmienić kolekcji';
            if (error.response.status) status = error.response.status;
        }

        return {
            message,
            status,
            ok: false,
            editedFurniture: null,
        }
    }
}


interface IEditFurnitureCategories extends IResponse {
    editedFurniture: IFurniture | null,
}

export interface IEditFurnitureCategoriesDto {
    furnitureId: string,
    categories: string[],
}

const editFurnitureCategories = async (furnitureData: IEditFurnitureCategoriesDto): Promise<IEditFurnitureCategories> => {
    const url = `${BASE_URL}/editFurnitureCategories/${furnitureData.furnitureId}`;
    const config: AxiosRequestConfig = {
        headers: ApiHelper.createHeader(),
    }

    const data = new FormData();
    furnitureData.categories.forEach((category, index) => {
        data.append(`categories[${index}]`, category);
    });

    try {
        const res = await axios.patch(url, data, config);

        const resoult = {
            message: 'Zmieniono kategorie',
            status: res.status,
            ok: true,
            editedFurniture: res.data.editedFurniture,
        }

        return resoult;
    } catch (error) {
        let message = 'Nie zmieniono kategorii';
        let status = 400;

        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 404) message = 'Nie znaleziono mebla';
            if (error.response.status === 403) message = 'Nie można zmienić kategorii';
            if (error.response.status) status = error.response.status;
        }

        return {
            message,
            status,
            ok: false,
            editedFurniture: null,
        }
    }
}


interface IEditPremises extends IResponse {
    editedFurniture: IFurniture | null,
}

export interface IEditPremisesDto {
    furnitureId: string,
    premises: string[],
}

const editPremises = async (furnitureData: IEditPremisesDto): Promise<IEditPremises> => {
    const url = `${BASE_URL}/editPremises/${furnitureData.furnitureId}`;
    const config: AxiosRequestConfig = {
        headers: ApiHelper.createHeader(),
    }

    const data = new FormData();
    furnitureData.premises.forEach((premise, index) => {
        data.append(`premises[${index}]`, premise);
    });

    try {
        const res = await axios.patch(url, data, config);

        const resoult = {
            message: 'Zmieniono pomieszczenia',
            status: res.status,
            ok: true,
            editedFurniture: res.data.editedFurniture,
        }

        return resoult;
    } catch (error) {
        let message = 'Nie zmieniono pomieszczeń';
        let status = 400;

        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 404) message = 'Nie znaleziono mebla';
            if (error.response.status === 403) message = 'Nie można zmienić pomieszczeń';
            if (error.response.status) status = error.response.status;
        }

        return {
            message,
            status,
            ok: false,
            editedFurniture: null,
        }
    }
}




const furnitureController = {
    getAllFurniture,
    getPieceOfFurniture,
    addFurniture,
    editFurniture,
    deleteFurniture,
    addImagesToSingleFurniture,
    deleteImageFromFurniture,
    moveFurnitureImage,
    getAllAdminFurniture,
    editFurnitureName,
    editDimension,
    editPrice,
    changePriceVisibility,
    editDescription,
    editProducer,
    editCollection,
    editFurnitureCategories,
    editPremises,
}

export default furnitureController;