import { Box, Button, Card, IconButton } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { IAddFurnitureDto } from "../../../../../../Api/furnitureController";
import DetailsComponent from "./components/DetailsComponent";
import PremisesComponent from "./components/PremisesComponent";
import CategoriesComponent from "./components/CategoriesComponent";
import MainImageComponent from "./components/MainImageComponent";
import ImagesComponent from "./components/ImagesComponent";
import { Close } from "@mui/icons-material";

interface PieceOfFurnitureProps {
    commonValues: any;
    furnitureData: IAddFurnitureDto;
    onPushFurniture: (
        furnitureData: IAddFurnitureDto
    ) => void;
    onAddFurniture: boolean;
    onRemoveFurniture: (randomKey: string) => void;
}

const PieceOfFurniture: FC<PieceOfFurnitureProps> = ({
    commonValues,
    furnitureData,
    onPushFurniture,
    onAddFurniture,
    onRemoveFurniture,
}) => {
    const [producerId, setProducerId] = useState<string>("");
    const [collectionId, setCollectionId] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [price, setPrice] = useState<number>(0);
    const [crossedPrice, setCrossedPrice] = useState<number>(0);
    const [width, setWidth] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);
    const [depth, setDepth] = useState<number>(0);
    const [description, setDescription] = useState<string>("");
    const [isPriceVissible, setIsPriceVissible] = useState<boolean>(false);
    const [premises, setPremises] = useState<string[]>([]);
    const [categories, setCategories] = useState<string[]>([]);
    const [images, setImages] = useState<File[]>(furnitureData.images || []);
    const [commonButtonColor, setCommonButtonColor] = useState<'warning' | 'success'>("warning");


    const handleChangePremiseState = (premiseItem: string) => {
        const premiseItemUpper = premiseItem.toUpperCase();
        if (
            premises?.map((name) => name.toUpperCase()).includes(premiseItemUpper)
        ) {
            setPremises((prevState) =>
                (prevState ?? []).filter(
                    (premise: string) => premise.toUpperCase() !== premiseItemUpper
                )
            );
        } else {
            setPremises((prevState) => [...(prevState ?? []), premiseItem]);
        }
    };


    const handleCopyCommonValues = () => {
        setCommonButtonColor("success");
        setName(commonValues.name);
        setPrice(commonValues.price);
        setCrossedPrice(commonValues.crossed);
        setWidth(commonValues.width);
        setHeight(commonValues.height);
        setDepth(commonValues.depth);
        setDescription(commonValues.description);
        setIsPriceVissible(commonValues.isPriceVissible);
        setPremises(commonValues.premises);
        setProducerId(commonValues.producerId);
        setCollectionId(commonValues.collectionId);
    };


    useEffect(() => {
        if (!onAddFurniture) return;
        const newFurnitureData: IAddFurnitureDto = {
            randomKey: furnitureData.randomKey,
            name: name,
            price,
            crossed: crossedPrice,
            width,
            height,
            depth,
            description,
            isPriceVissible,
            premises,
            furnitureCategories: categories,
            producer: producerId,
            partCollection: collectionId,
            images,
        };

        onPushFurniture(newFurnitureData);
    }, [
        onAddFurniture,
        name,
        price,
        crossedPrice,
        width,
        height,
        depth,
        description,
        isPriceVissible,
        premises,
        categories,
        producerId,
        collectionId,
        images,
        onPushFurniture,
        furnitureData.randomKey,
    ]);


    return (
        <Box
            sx={{
                width: "100%",
                maxWidth: "1465px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                pb: 1,
            }}
        >
            <Card
                sx={{
                    width: "96%",
                    p: 2,
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "end", pb: 2 }}>
                    <IconButton
                        onClick={() => {
                            if (!furnitureData.randomKey) return;
                            onRemoveFurniture(furnitureData.randomKey)
                        }}
                        color='error'
                    >
                        <Close />
                    </IconButton>
                </Box>
                <Button
                    onClick={() => handleCopyCommonValues()}
                    color={commonButtonColor}
                    sx={{
                        width: "100%",
                        height: "50px",
                    }}
                    variant="contained"
                >
                    {commonButtonColor === "warning" ? 'Skopiuj wartości wspólne' : 'Skopiowano'}
                </Button>
                <Card sx={{ m: 1 }}>
                    <Box sx={{ display: "flex", alignItems: "start" }}>
                        <MainImageComponent imageSrc={images[0]} />
                        <DetailsComponent
                            name={name}
                            setName={setName}
                            price={price}
                            setPrice={setPrice}
                            width={width}
                            setWidth={setWidth}
                            depth={depth}
                            setDepth={setDepth}
                            height={height}
                            setHeight={setHeight}
                            description={description}
                            setDescription={setDescription}
                            crossedPrice={crossedPrice}
                            setCrossedPrice={setCrossedPrice}
                            isPriceVissible={isPriceVissible}
                            setIsPriceVissible={setIsPriceVissible}
                        />
                    </Box>
                    <ImagesComponent images={images} onSetImages={setImages} />
                    <Box sx={{ display: "flex" }}>
                        <CategoriesComponent
                            categoryState={categories}
                            onSetCategories={setCategories}
                        />
                        <PremisesComponent
                            premises={premises}
                            onSetPremises={handleChangePremiseState}
                        />
                    </Box>
                </Card>
            </Card>
        </Box>
    );
};

export default PieceOfFurniture;
