import { Card, FormControl, InputLabel, Select, MenuItem, Box } from "@mui/material";
import { FC } from "react";
import { IProducer } from "../../../../../../../interfaces/IProducer";
import { getShowroomUrl } from "../../../../../../../services/getShowroomUrl";
import styled from "@emotion/styled";

const BASE_URL = getShowroomUrl();


const ProducerImage = styled.img`
    width: 200px;
    height: 100px;
    object-fit: contain;
`


interface ProducerComponentProps {
    producersData: IProducer[] | null;
    producerId: string;
    onChangeProducerId: (producerId: string) => void;
}

const ProducerComponent: FC<ProducerComponentProps> = ({
    producersData,
    producerId,
    onChangeProducerId
}) => {

    

    if (!producersData) return <Card>Error</Card>


    return (
        <div>
            <Card sx={{ p: 4 }}>
                <FormControl fullWidth>
                    <InputLabel>Producent</InputLabel>
                    <Select
                        value={producerId}
                        label="Producent"
                        onChange={e => onChangeProducerId(e.target.value)}
                    >
                        {producersData.map((producerItem) => (
                            <MenuItem
                                key={producerItem._id}
                                value={producerItem._id}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ProducerImage
                                        src={`${BASE_URL}${producerItem.logo}`}
                                        alt={producerItem.name}
                                    />
                                </Box>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Card>
        </div>
    )
}

export default ProducerComponent;