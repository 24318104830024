import styled from "@emotion/styled";
import { DoneOutline, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails, Card, CardContent, ImageList, Divider, Icon } from "@mui/material";
import { FC, useState } from "react";
import { IPremise } from "../../../../../interfaces/IPremise";
import { premisesData } from "../../../../../pages/Premises/premisesData";
import CustomButton from "../../../../_common/CustomButton";


const PremiseImage = styled.img`
    margin-top: 20px;
    width: 200px;
    height: 100px;
    object-fit: contain;
`

const PremisesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 40px;
    margin-bottom: 80px;
    user-select: none;
    max-width: 1100px;
`


interface PremisesProps {
    premises: string[] | null,
    expanded: string | false,
    onClickAccordion: (accordionName: string) => void,
    onSetPremises: (premise: string) => void,
    onSetExpanded: (expandName: string) => void,
}

const Premises: FC<PremisesProps> = ({
    premises,
    expanded,
    onClickAccordion,
    onSetPremises,
    onSetExpanded,
}) => {
    const [showOk, setShowOk] = useState(false)

    return (
        <Accordion expanded={expanded === 'Pomieszczenia'}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
                onClick={() => onClickAccordion('Pomieszczenia')}
            >
                4. Pomieszczenia
                {showOk &&
                    <Icon sx={{ ml: 1 }}>
                        <DoneOutline color="success" />
                    </Icon>
                }
            </AccordionSummary>
            <AccordionDetails>
                <ImageList
                    sx={{
                        pt: 4,
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                    }}
                >
                    <PremisesContainer>
                        {premisesData.map((premise: IPremise) => (
                            <Card key={premise.name}
                                sx={{
                                    m: 1,
                                    p: 1,
                                    '&:hover': {
                                        transform: 'scale(1.15)',
                                    },
                                    transition: 'transform 0.2s, box-shadow 0.2s',
                                    cursor: 'pointer',
                                    boxShadow: premises?.map(name => name.toLowerCase()).includes(premise.name.toLowerCase())
                                        ?
                                        '0px 4px 20px rgba(0, 42, 255, 0.5)'
                                        :
                                        '0px 1px 3px rgba(0, 0, 0, 0.2)'
                                }}
                                onClick={() => onSetPremises(premise.name)}
                            >
                                <PremiseImage
                                    src={`${premise.image}`}
                                    alt={premise.name}
                                />
                                <CardContent sx={{ textAlign: 'center' }}>
                                    {premise.name.toUpperCase()}
                                </CardContent>
                            </Card>))
                        }
                    </PremisesContainer>
                </ImageList>
                <Divider sx={{ m: 2 }} />
                <CustomButton
                    sx={{ width: '100%' }}
                    text="Dalej"
                    onClick={() => {
                        onSetExpanded('Kategorie');
                        setShowOk(true);
                    }}
                />
            </AccordionDetails>
        </Accordion>
    )
}

export default Premises;