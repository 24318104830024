import { Box, FormControlLabel, Switch } from "@mui/material";
import { FC } from "react";
import CustomInput from "../../../../../../../components/_common/CustomInput";
import styled from "@emotion/styled";


const MyInput = styled(CustomInput)`
    width: '100%'; 
    margin: 2;
`


interface DetailsComponentProps {
    name: string,
    setName: (name: string) => void,
    price: number,
    setPrice: (price: number) => void,
    crossedPrice: number,
    setCrossedPrice: (crossedPrice: number) => void,
    width: number,
    setWidth: (width: number) => void,
    height: number,
    setHeight: (height: number) => void,
    depth: number,
    setDepth: (depth: number) => void,
    description: string,
    setDescription: (description: string) => void,
    isPriceVissible: boolean,
    setIsPriceVissible: (isPriceVissible: boolean) => void,
}

const DetailsComponent: FC<DetailsComponentProps> = ({
    name,
    setName,
    price,
    setPrice,
    crossedPrice,
    setCrossedPrice,
    width,
    setWidth,
    height,
    setHeight,
    depth,
    setDepth,
    description,
    setDescription,
    isPriceVissible,
    setIsPriceVissible,
}) => {
    
    return (
        <Box sx={{ width: '75%' }}>
            <Box sx={{ display: 'flex' }}>
                <Box sx={{ width: '50%' }}>
                    <CustomInput
                        variant="filled"
                        value={name}
                        setValue={setName}
                        label="Nazwa"
                        mode="text"
                        sx={{ width: '100%', m: 2 }}
                    />
                    <CustomInput
                        variant="filled"
                        value={price}
                        setValue={setPrice}
                        label="Cena"
                        mode="numeric"
                        sx={{ width: '100%', m: 2 }}
                    />
                    <CustomInput
                        variant="filled"
                        value={crossedPrice}
                        setValue={setCrossedPrice}
                        label="Cena skreślona"
                        mode="numeric"
                        sx={{ width: '100%', m: 2 }}
                    />
                </Box>
                <Box sx={{ marginLeft: '30px', width: '50%' }}>
                    <CustomInput
                        variant="filled"
                        value={width}
                        setValue={setWidth}
                        label="Szerokość"
                        mode="numeric"
                        sx={{ width: '100%', m: 2 }}
                    />
                    <CustomInput
                        variant="filled"
                        value={height}
                        setValue={setHeight}
                        label="Wysokość"
                        mode="numeric"
                        sx={{ width: '100%', m: 2 }}
                    />
                    <CustomInput
                        variant="filled"
                        value={depth}
                        setValue={setDepth}
                        label="Głębokość"
                        mode="numeric"
                        sx={{ width: '100%', m: 2 }}
                    />
                </Box>
            </Box>
            <Box>
                <FormControlLabel
                    sx={{ mb: 1, mt: 1, ml: 2 }}
                    control={
                        <Switch
                            checked={isPriceVissible}
                            onChange={(e) => setIsPriceVissible(e.target.checked)}
                        />
                    }
                    label="Wyświetlaj cenę mebla"
                />
            </Box>
            <Box>
                <MyInput
                    variant="filled"
                    value={description}
                    setValue={setDescription}
                    label="Opis"
                    mode="multiline"
                    sx={{ width: '100%', m: 2 }}
                    maxRows={100}
                />
            </Box>
        </Box>
    )
}

export default DetailsComponent;