import { Box, Card } from "@mui/material";
import { FC, useRef } from "react";
import { v4 as uuidv4 } from 'uuid';

interface DropZoneProps {
    onSetFurniture: any;
}

const DropZone: FC<DropZoneProps> = ({
    onSetFurniture,
}) => {
    const fileInputRef = useRef<HTMLInputElement>(null);


    const handleDrop = (e: any) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        const newFurniture = files.map((file, index) => ({
            id: index + 1,
            name: '',
            producer: '',
            partCollectionId: '',
            price: 0,
            width: 0,
            height: 0,
            depth: 0,
            crossed: 0,
            isPriceVissible: false,
            description: '',
            images: [file],
            premises: [],
            furnitureCategories: [],
            randomKey: uuidv4(),
        }));

        onSetFurniture((prevState: any) => [...prevState, ...newFurniture]);
    };


    const handleFileSelect = (e: any) => {
        const files = Array.from(e.target.files) as File[];
        const newFurniture = files.map((file, index) => ({
            id: index + 1,
            name: '',
            producer: '',
            partCollectionId: '',
            price: 0,
            width: 0,
            height: 0,
            depth: 0,
            crossed: 0,
            isPriceVissible: false,
            description: '',
            images: [file],
            premises: [],
            furnitureCategories: [],
            randomKey: uuidv4(),
        }));

        onSetFurniture((prevState: any) => [...prevState, ...newFurniture]);
    };


    const handleDragOver = (e: any) => {
        e.preventDefault();
    };


    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, width: '100%' }}>
            <Card
                sx={{
                    backgroundColor: '#eac5be',
                    border: '3px solid #c85742',
                    width: '100%',
                    maxWidth: '1400px',
                    p: 2,
                    height: '500px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onClick={() => fileInputRef.current?.click()}
            >
                Przeciągnij zdjęcia tutaj lub kliknij, aby dodać
            </Card>
            <input
                type="file"
                multiple
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileSelect}
            />
        </Box>
    )
}

export default DropZone;