import { FormControlLabel, Switch, Typography } from "@mui/material";
import { FC } from "react";
import CustomInput from "../../../../../../../components/_common/CustomInput";

interface DetailsProps {
    name: string;
    setName: (name: string) => void;
    price: number;
    setPrice: (price: number) => void;
    crossed: number;
    setCrossed: (crossed: number) => void;
    isPriceVissible: boolean;
    setIsPriceVissible: (isPriceVissible: boolean) => void;
    width: number;
    setWidth: (width: number) => void;
    height: number;
    setHeight: (height: number) => void;
    depth: number;
    setDepth: (depth: number) => void;
    description: string;
    setDescription: (description: string) => void;
}

const DetailsComponent: FC<DetailsProps> = ({
    name,
    setName,
    price,
    setPrice,
    crossed,
    setCrossed,
    isPriceVissible,
    setIsPriceVissible,
    width,
    setWidth,
    height,
    setHeight,
    depth,
    setDepth,
    description,
    setDescription,
}) => {
    return (
        <>
            <Typography
                sx={{
                    p: 4,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
                variant="h5">
                Szczegóły
            </Typography>
            <CustomInput
                variant='filled'
                sx={{ width: '100%', mt: 2 }}
                mode="text"
                label="Nazwa"
                placeholder="Nazwa"
                value={name}
                setValue={setName}
            />
            <CustomInput
                variant='filled'
                sx={{ width: '100%', mt: 2 }}
                mode="numeric"
                label="Cena"
                placeholder="Nazwa"
                value={price}
                setValue={setPrice}
            />
            <CustomInput
                variant='filled'
                sx={{ width: '100%', mt: 2 }}
                mode="numeric"
                label="Cena skreślona"
                placeholder="Nazwa"
                value={crossed}
                setValue={setCrossed}
            />
            <FormControlLabel
                sx={{ mb: 1.2, mt: 2.5 }}
                control={
                    <Switch
                        checked={isPriceVissible}
                        onChange={(e) => setIsPriceVissible(e.target.checked)}
                    />
                } label="Wyświetlaj cenę mebla"
            />
            <CustomInput
                variant='filled'
                sx={{ width: '100%', mt: 2 }}
                mode="numeric"
                label="Szerokość"
                placeholder="Nazwa"
                value={width}
                setValue={setWidth}
            />
            <CustomInput
                variant='filled'
                sx={{ width: '100%', mt: 2 }}
                mode="numeric"
                label="Wysokość"
                placeholder="Nazwa"
                value={height}
                setValue={setHeight}
            />
            <CustomInput
                variant='filled'
                sx={{ width: '100%', mt: 2 }}
                mode="numeric"
                label="Głębokość"
                placeholder="Nazwa"
                value={depth}
                setValue={setDepth}
            />
            <CustomInput
                variant='filled'
                sx={{ width: '100%', mt: 2 }}
                mode='multiline'
                label='Opis'
                placeholder='Opis'
                value={description}
                setValue={setDescription}
                maxRows={100}
            />
        </>
    )
}

export default DetailsComponent;