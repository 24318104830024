import IResponse from '../interfaces/IResponse';
import { getShowroomUrl } from '../services/getShowroomUrl';
import axios, { AxiosRequestConfig } from 'axios';
import ApiHelper from './ApiHelper';

const BASE_URL = `${getShowroomUrl()}api/viewCounter`;


interface IGetViews extends IResponse {
    views: any[] | null,
    weeklyViews: number | null,
    monthlyViews: number | null,
    viewCount: number | null,
    dailyStatsPerYear: any[] | null,
    hourlyStatsPerYear: any[] | null,
    hourlyStatsPerDayByYear: any[] | null,
    monthlyStatsFromAllYears: any[] | null,
}

const getStatisticsData = async (): Promise<IGetViews> => {
    const url = `${getShowroomUrl()}api/viewsData`;
    const header: AxiosRequestConfig = {
        headers: ApiHelper.createHeader(),
    }
    
    try {
        const res = await axios.get(url, header)

        const resoult = {
            message: 'Pobrano wyświetlenia',
            ok: true,
            status: res.status,
            views: res.data.views,
            weeklyViews: res.data.weeklyViews,
            monthlyViews: res.data.monthlyViews,
            viewCount: res.data.viewCount,
            dailyStatsPerYear: res.data.dailyStatsPerYear,
            hourlyStatsPerYear: res.data.hourlyStatsPerYear,
            hourlyStatsPerDayByYear: res.data.hourlyStatsPerDayByYear,
            monthlyStatsFromAllYears: res.data.monthlyStatsFromAllYears,
        }

        return resoult;
    } catch (error) {

         let message = 'Nie pobrano wyświetleń'
         let status = 400

         if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 403) message = 'Nie możesz tego zrobić'
            if (error.response.status) status = error.response.status
         }

         return {
            message,
            status,
            ok: false,
            views: null,
            weeklyViews: null,
            monthlyViews: null,
            viewCount: null,
            dailyStatsPerYear: null,
            hourlyStatsPerYear: null,
            hourlyStatsPerDayByYear: null,
            monthlyStatsFromAllYears: null,
         }
    }
}


interface IGetCountsValue extends IResponse {
    counts: number | null,
}


const getCounts = async (): Promise<IGetCountsValue> => {
    const url = BASE_URL;

    const header: AxiosRequestConfig = {
        headers: ApiHelper.createHeader(),
    }

    try {
        const res = await axios.get(url, header)

        const resoult = {
            message: 'Pobrano wyświetlenia',
            ok: true,
            status: res.status,
            counts: res.data.counts
        }

        return resoult;
    } catch (error) {

         let message = 'Nie pobrano wyświetleń'
         let status = 400

         if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 403) message = 'Nie możesz tego zrobić'
            if (error.response.status) status = error.response.status
         }

         return {
            message,
            status,
            ok: false,
            counts: null,
         }
    }
}

const newView = async () => {
    const response = await axios.post(`${BASE_URL}`)
    return response;
}

const viewCounterController = {
    getCounts,
    newView,
    getStatisticsData,
};

export default viewCounterController;