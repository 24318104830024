import styled from "@emotion/styled";
import { FC } from "react";
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";


const Container = styled.div`
    display: flex;
    justify-content: center;
`


interface BarChartComponentProps {
    data: any;
    yearToDisplay: number | string | null;
}

const BarChartComponent: FC<BarChartComponentProps> = ({
    data,
    yearToDisplay,
}) => {

    if (!yearToDisplay) return (<></>)

    return (
        <Container>
            <BarChart
                width={1000}
                height={420}
                data={data[yearToDisplay]}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                barSize={20}
            >
                <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                <YAxis />
                <Tooltip />
                <Legend />
                <CartesianGrid strokeDasharray="3 3" />
                <Bar dataKey="pv" name='Wejścia na stronę' fill="#283890" background={{ fill: '#eee' }} />
            </BarChart>
        </Container>
    )
}

export default BarChartComponent;