import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useUser } from "../../../../hooks/useUser";
import { Box, Button, Dialog, LinearProgress, Typography } from "@mui/material";
import CustomButton from "../../../../components/_common/CustomButton";
import { useNavigate } from "react-router-dom";
import DropZone from "./components/DropZone";
import CommonValues from "./components/CommonValues/CommonValues";
import PieceOfFurniture from "./components/PieceOfFurniture/PieceOfFurniture";
import producersController from "../../../../Api/producersController";
import { IProducer } from "../../../../interfaces/IProducer";
import { useToast } from "../../../../hooks/useToast";
import { ICollection } from "../../../../interfaces/ICollection";
import collectionsController from "../../../../Api/collectionsController";
import furnitureController, {
    IAddFurnitureDto,
} from "../../../../Api/furnitureController";
import { useModal } from "../../../../hooks/useModal";
import FormLayout from "../../../../components/forms/FormLayout";
import SalesWindow from "../../../../components/_common/SalesWindow/SalesWindow";
import LoadingAnimation from "../../../../components/_common/Loading";


interface ClartnetProps { }

const Clartnet: FC<ClartnetProps> = () => {
    const user = useUser();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [addingCounter, setAddingCounter] = useState<number>(0);
    const [furnitureCounter, setFurnitureCounter] = useState<number>(0);
    const [errorCounter, setErrorCounter] = useState<number>(0);
    const [collectionsData, setCollectionsData] = useState<ICollection[] | null>(null);
    const [producersData, setProducersData] = useState<IProducer[] | null>(null);
    const [commonValues, setCommonValues] = useState({});
    const [newFurniture, setNewFurniture] = useState<IAddFurnitureDto[]>([]);
    //eslint-disable-next-line
    const [furnitureToAdd, setFurnitureToAdd] = useState<IAddFurnitureDto[]>([]);
    const [pushFurnitureData, setPushFurnitureData] = useState<boolean>(false);
    const toast = useRef(useToast()).current;
    const modal = useRef(useModal()).current;


    const getCommonValues = useCallback((commonValues: {}) => {
        setCommonValues(commonValues);
    }, [
        setCommonValues,
    ])


    const addFurnitureHandler = useCallback(async (data: IAddFurnitureDto[]) => {
        setPushFurnitureData(false);
        setFurnitureToAdd([]);
        setLoading(true);
        setAddingCounter(1);
        setFurnitureCounter(data.length);
        setErrorCounter(0);
        let isError = 0;
        for (const item of data) {
            const furniture: IAddFurnitureDto = {
                name: item.name,
                producer: item.producer,
                partCollection: item.partCollection,
                description: item.description,
                price: item.price,
                width: item.width,
                depth: item.depth,
                height: item.height,
                crossed: item.crossed,
                premises: item.premises,
                isPriceVissible: item.isPriceVissible,
                furnitureCategories: item.furnitureCategories,
                images: item.images,
            };


            try {
                if (item.name && item.producer && item.images.length !== 0) {
                    const res = await furnitureController.addFurniture(furniture);
                    if (res.ok) {
                        setNewFurniture((prevFurniture) =>
                            prevFurniture.filter((furniture) => furniture.randomKey !== item.randomKey)
                        );
                        setAddingCounter((prevCounter) => prevCounter + 1);
                    }
                } else {
                    toast.error("Dodaj niezbędne dane w " + item.name);
                    isError = 1;
                    setErrorCounter((prevCounter) => prevCounter + 1);
                    setAddingCounter((prevCounter) => prevCounter + 1);
                }
            } catch (error) {
                isError = 1;
                if (error instanceof Error) {
                    toast.error(error.message);
                    setErrorCounter((prevCounter) => prevCounter + 1);
                    setAddingCounter((prevCounter) => prevCounter + 1);
                } else {
                    toast.error("Błąd " + item.name);
                    setErrorCounter((prevCounter) => prevCounter + 1);
                    setAddingCounter((prevCounter) => prevCounter + 1);
                }
            }
        }
        modal.close();
        setLoading(false);
        if (isError === 0) toast.success('Meble dodane pomyślnie')

    }, [
        modal,
        toast,
    ]);


    const changeFurnitureData = useCallback((furnitureData: IAddFurnitureDto) => {
        setFurnitureToAdd((prevFurniture) => {
            const updatedFurniture = [...prevFurniture, furnitureData];
            if (updatedFurniture.length === newFurniture.length) {
                setPushFurnitureData(true);
            }
            modal.open(
                <FormLayout
                    title="Dodawanie mebli"
                    buttonText="Dodaj meble"
                    onClickClose={() => {
                        modal.close();
                        setFurnitureToAdd([]);
                        setPushFurnitureData(false);
                    }}
                    onClickButton={() => addFurnitureHandler(updatedFurniture as any)}
                    maxWidth='xl'
                >
                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-around',
                    }}>
                        {updatedFurniture.map((furniture) => (
                            <SalesWindow
                                id='d'
                                type="furniture"
                                link={null}
                                name={furniture.name}
                                width={furniture.width}
                                depth={furniture.depth}
                                height={furniture.height}
                                price={furniture.price}
                                crossed={furniture.crossed}
                                isPriceVissible={true}
                                image={URL.createObjectURL(furniture.images[0])}
                                mode='clarnet'
                            />
                        ))}
                    </Box>
                </FormLayout>
            )
            return updatedFurniture;
        });
    }, [
        newFurniture.length,
        modal,
        addFurnitureHandler,
    ])


    const getCollections = useCallback(async (producerId: string) => {
        if (!producerId) {
            return;
        }
        const res = await collectionsController.getAllProducerCollections(
            producerId
        );
        if (res.ok) {
            setCollectionsData(res.collections);
        }
    }, []);


    const getProducers = useCallback(async () => {
        const res = await producersController.getAllProducers();
        if (res.ok) {
            setProducersData(res.producers);
        }
        if (!res.ok) {
            toast.error(res.message);
        }
    }, [toast]);


    const removeFurnitureFromForm = useCallback((furnitureKey: string) => {
        setNewFurniture((prevFurniture) =>
            prevFurniture.filter((furniture) => furniture.randomKey !== furnitureKey)
        );
    }, [])


    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, []);


    const handleBeforeUnload = (e: any) => {
        e.preventDefault();
        e.returnValue = "";
    };


    useEffect(() => {
        getProducers();
    }, [getProducers]);


    if (!user.isAuthMinsk && !user.isAuthPiaseczno) {
        return (
            <Box
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    height: "100vh",
                }}
            >
                <div>
                    <h1>Brak dostępu</h1>
                    <CustomButton
                        sx={{ width: "100%" }}
                        text="Zaloguj się"
                        onClick={() => navigate("/logowanie")}
                    />
                </div>
            </Box>
        );
    }


    return (
        <Box
            sx={{
                backgroundColor: "#232323",
                color: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                pb: 100,
            }}
        >
            <Dialog open={loading}>
                <LoadingAnimation style={{
                    maxWidth: '250px',
                    maxHeight: '250px',
                    padding: '50px',
                    paddingBottom: '0px',
                }} />
                <Typography
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    Dodawanie mebli {addingCounter}/{furnitureCounter}
                </Typography>
                <LinearProgress
                    sx={{ m: 1 }}
                    variant="buffer"
                    value={((addingCounter) / furnitureCounter) * 100}
                />
                {errorCounter > 0 && (
                    <Typography
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            color: "red",
                            p: 1,
                        }}
                    >
                        Błąd dodawania mebli: {errorCounter}
                    </Typography>
                )}
                <Typography sx={{ mb: 4 }} />
            </Dialog>
            <Typography
                variant="h2"
                sx={{
                    maxWidth: "1400px",
                    width: "100%",
                    backgroundColor: "#eac5be",
                    justifyContent: "center",
                    display: "flex",
                    border: "3px solid #c85742",
                    m: 5,
                    p: 2,
                    borderRadius: "10px",
                }}
            >
                {user.isAuthMinsk && "Mińsk Mazowiecki"}
                {user.isAuthPiaseczno && "Piaseczno"}
            </Typography>
            <CommonValues
                producersData={producersData}
                collectionsData={collectionsData}
                onGetProducers={getProducers}
                onGetCollections={getCollections}
                onPushCommonValues={getCommonValues}
            />
            <DropZone onSetFurniture={setNewFurniture} />
            {newFurniture.length > 0 && (
                <>
                    <Typography
                        sx={{
                            pt: 2,
                            pb: 2,
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                        variant="h4"
                    >
                        Dodawanie mebli
                    </Typography>
                    {newFurniture.map((furniture) => (
                        <PieceOfFurniture
                            key={furniture.randomKey}
                            furnitureData={furniture}
                            commonValues={commonValues}
                            onAddFurniture={pushFurnitureData}
                            onPushFurniture={changeFurnitureData}
                            onRemoveFurniture={removeFurnitureFromForm}
                        />
                    ))}
                </>
            )}
            <Button
                sx={{ position: "fixed", bottom: 3 }}
                variant="contained"
                color="success"
                onClick={() => {
                    newFurniture.length > 0 && setPushFurnitureData(true);
                }}
            >
                Dalej
            </Button>
        </Box>
    );
};

export default Clartnet;
