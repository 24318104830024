import { IconButton } from '@mui/material';
import { useCart } from '../../../hooks/useCart';
import styles from './salesWindow.module.scss';
import { FC, useState } from 'react';
import { AddShoppingCartSharp, ShoppingCart } from '@mui/icons-material';
import styled from '@emotion/styled';

const AddIconButton = styled(IconButton) <{ showButton: boolean, isInCart: boolean }>`
    position: absolute;
    right: 15px;
    top: 15px;
    border: 1px solid ${({ isInCart }) => isInCart ? 'transparent' : 'green'};   
    background-color: ${({ isInCart }) => isInCart ? 'none' : 'white'};
    display: ${({ showButton }) => showButton ? 'flex' : 'none'};
    transition: all 0.2s ease;

    &:hover {
        background-color: ${({ isInCart }) => isInCart ? '#ffa9a9' : '#a4ee90'};
        border: ${({ isInCart }) => isInCart ? '1px solid red' : ''};
    }

    @media screen and (max-width: 940px) {
        display: flex;
    }
`;


interface SalesWindowProps {
    id: string,
    name: string,
    width: number | null,
    depth: number | null,
    height: number | null,
    price?: number | null,
    crossed?: number | null,
    isPriceVissible: boolean,
    image: string,
    link?: string | null,
    type: 'furniture' | 'imported furniture',
    mode?: 'clarnet'
}

const SalesWindow: FC<SalesWindowProps> = ({
    id,
    name,
    width,
    depth,
    height,
    price,
    crossed,
    isPriceVissible,
    image,
    link,
    type,
    mode,
}) => {
    const cart = useCart();
    const [showAddButton, setShowAddButton] = useState(false)
    const pageURL = window.location.origin;

    const onClickAddFurniture = () => {
        if (cart.isInCart(id)) {
            return cart.remove(id);
        }

        cart.add({
            type,
            name,
            id,
            image,
            link: `${pageURL}/${link}`,
        });
    }


    return (
        <div className={styles.container}
            onMouseEnter={() => setShowAddButton(true)}
            onMouseLeave={() => setShowAddButton(false)}
        >
            <div>
                <a href={link ? `/${link}` : undefined} target={link ? "_blank" : undefined} rel="noopener noreferrer">
                    <div style={{
                        position: 'relative',
                    }}>
                        {
                            (mode !== 'clarnet' && !price) &&
                            <AddIconButton
                                isInCart={cart.isInCart(id)}
                                showButton={showAddButton || cart.isInCart(id)}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    onClickAddFurniture();
                                }}
                            >
                                {
                                    cart.isInCart(id) ?
                                        <ShoppingCart color='disabled' />
                                        :
                                        <AddShoppingCartSharp color='success' />
                                }
                            </AddIconButton>
                        }
                    </div>
                    <div className={styles.imageDiv}>
                        <img className={styles.image} src={image} key={name} alt={name} />
                    </div>
                    <div className={styles.name}>
                        {name}
                    </div>
                    {width ? (
                        <div className={styles.dimensions}>
                            <div className={styles.dimension}>
                                Szerokość:
                            </div>
                            <div className={styles.dimensionValue}>
                                {width} cm
                            </div>
                        </div>
                    ) : (
                        <div style={{ color: 'white' }} className={styles.dimensions}>
                            <div style={{ color: 'white' }} className={styles.dimension}>
                            </div>
                            <div style={{ color: 'white' }} className={styles.dimensionValue}>
                                cm
                            </div>
                        </div>
                    )}
                    {depth ? (
                        <div className={styles.dimensions}>
                            <div className={styles.dimension}>
                                Głębokość:
                            </div>
                            <div className={styles.dimensionValue}>
                                {depth} cm
                            </div>
                        </div>
                    ) : (
                        <div style={{ color: 'white' }} className={styles.dimensions}>
                            <div style={{ color: 'white' }} className={styles.dimension}>
                            </div>
                            <div style={{ color: 'white' }} className={styles.dimensionValue}>
                                cm
                            </div>
                        </div>
                    )}
                    {height ? (
                        <div className={styles.dimensions}>
                            <div className={styles.dimension}>
                                Wysokość:
                            </div>
                            <div className={styles.dimensionValue}>
                                {height} cm
                            </div>
                        </div>
                    ) : (
                        <div style={{ color: 'white' }} className={styles.dimensions}>
                            <div style={{ color: 'white' }} className={styles.dimension}>
                            </div>
                            <div style={{ color: 'white' }} className={styles.dimensionValue}>
                                cm
                            </div>
                        </div>
                    )}
                </a>
                {isPriceVissible ? (
                    <div className={styles.price}>
                        {price}zł
                        <div className={styles.crossed}>
                            {crossed ? `${crossed}zł` : ''}
                        </div>
                    </div>
                ) : (
                    <div className={styles.price}>
                    </div>
                )}
            </div>
        </div>
    )
}

export default SalesWindow;