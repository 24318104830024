import { ImageList, Card, CardContent, Typography } from "@mui/material";
import { FC } from "react";
import { IPremise } from "../../../../../../../interfaces/IPremise";
import { premisesData } from "../../../../../../Premises/premisesData";
import styled from "@emotion/styled";


const PremiseImage = styled.img`
    margin-top: 20px;
    width: 200px;
    height: 100px;
    object-fit: contain;
`

const PremisesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 40px;
    margin-bottom: 80px;
    user-select: none;
    max-width: 1100px;
`


interface PremisesComponentProps {
    premises: string[] | null,
    onSetPremises: (premise: string) => void,
}

const PremisesComponent: FC<PremisesComponentProps> = ({
    premises,
    onSetPremises,
}) => {
    return (
        <ImageList
            sx={{
                pt: 4,
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}
        >
            <Typography
                sx={{
                    p: 4,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
                variant="h5">Pomieszczenia
            </Typography>
            <PremisesContainer>
                {premisesData.map((premise: IPremise) => (
                    <Card key={premise.name}
                        sx={{
                            m: 1,
                            p: 1,
                            '&:hover': {
                                transform: 'scale(1.15)',
                            },
                            transition: 'transform 0.2s, box-shadow 0.2s',
                            cursor: 'pointer',
                            boxShadow: premises?.map(name => name.toLowerCase()).includes(premise.name.toLowerCase())
                                ?
                                '0px 4px 20px rgba(0, 42, 255, 0.5)'
                                :
                                '0px 1px 3px rgba(0, 0, 0, 0.2)'
                        }}
                        onClick={() => onSetPremises(premise.name)}
                    >
                        <PremiseImage
                            src={`${premise.image}`}
                            alt={premise.name}
                        />
                        <CardContent sx={{ textAlign: 'center' }}>
                            {premise.name.toUpperCase()}
                        </CardContent>
                    </Card>))
                }
            </PremisesContainer>
        </ImageList>
    )
}

export default PremisesComponent;