import { Card, FormControl, InputLabel, Select, MenuItem, Box, Typography } from "@mui/material";
import { FC } from "react";
import { getShowroomUrl } from "../../../../../../../services/getShowroomUrl";
import { ICollection } from "../../../../../../../interfaces/ICollection";
import styled from "@emotion/styled";

const BASE_URL = getShowroomUrl();


const CollectionImage = styled.img`
    width: 200px;
    height: 100px;
    object-fit: contain;
`


interface CollectionComponentProps {
    disabled: boolean;
    collectionId: string;
    onSetCollectionId: (collectionId: string) => void;
    collectionsData: ICollection[] | null;
}

const CollectionComponent: FC<CollectionComponentProps> = ({
    disabled,
    collectionId,
    onSetCollectionId,
    collectionsData,
}) => {
    return (
        <Card sx={{ p: 4 }}>
            <FormControl fullWidth>
                <InputLabel>Kolekcja</InputLabel>
                <Select
                    disabled={disabled}
                    value={collectionId}
                    label="Kolekcja"
                    onChange={e => onSetCollectionId(e.target.value)}
                >
                    {collectionsData && collectionsData.map((collectionItem) => (
                        <MenuItem
                            key={collectionItem._id}
                            value={collectionItem._id}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CollectionImage
                                    src={`${BASE_URL}${collectionItem.image}`}
                                    alt={collectionItem.name}
                                />
                                <Typography variant="h6">{collectionItem.name}</Typography>
                            </Box>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Card>
    )
}

export default CollectionComponent;