import { Box, Button, Card, Divider, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { IProducer } from "../../../../../../interfaces/IProducer";
import { ICollection } from "../../../../../../interfaces/ICollection";
import ProducerComponent from "./components/ProducerComponent";
import CollectionComponent from "./components/CollectionComponent";
import DetailsComponent from "./components/DetailsComponent";
import PremisesComponent from "./components/PremisesComponent";


interface CommonValuesProps {
    producersData: IProducer[] | null;
    collectionsData: ICollection[] | null;
    onGetProducers: () => void;
    onGetCollections: (producerId: string) => void;
    onPushCommonValues: (commonValues: {}) => void;
}

const CommonValues: FC<CommonValuesProps> = ({
    producersData,
    collectionsData,
    onGetProducers,
    onGetCollections,
    onPushCommonValues,
}) => {
    const [name, setName] = useState<string>('');
    const [price, setPrice] = useState<number>(0);
    const [crossed, setCrossed] = useState<number>(0);
    const [isPriceVissible, setIsPriceVissible] = useState<boolean>(false);
    const [width, setWidth] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);
    const [depth, setDepth] = useState<number>(0);
    const [description, setDescription] = useState<string>('');
    const [producerId, setProducerId] = useState<string>('');
    const [collectionId, setCollectionId] = useState<string>('');
    const [premises, setPremises] = useState<string[]>([]);


    const handleChangeProducerId = (producerId: string) => {
        setProducerId(producerId)
        onGetCollections(producerId)
        setCollectionId('')
    }


    const handleChangePremiseState = (premiseItem: string) => {
        const premiseItemUpper = premiseItem.toUpperCase();
        if (premises?.map(name => name.toUpperCase()).includes(premiseItemUpper)) {
            setPremises(prevState => (prevState ?? []).filter((premise: string) => premise.toUpperCase() !== premiseItemUpper));
        } else {
            setPremises(prevState => [...(prevState ?? []), premiseItem]);
        }
    }


    const pushCommonValues = useCallback(() => {
        onPushCommonValues({
            name,
            price,
            crossed,
            isPriceVissible,
            width,
            height,
            depth,
            description,
            producerId,
            collectionId,
            premises,
        })
    }, [
        name,
        price,
        crossed,
        isPriceVissible,
        width,
        height,
        depth,
        description,
        producerId,
        collectionId,
        premises,
        onPushCommonValues
    ])


    useEffect(() => {
        pushCommonValues()
    }, [
        name,
        price,
        crossed,
        isPriceVissible,
        width,
        height,
        depth,
        description,
        producerId,
        pushCommonValues
    ])


    if (!producersData) return <Card>Error</Card>


    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
        }}>
            <Card sx={{
                width: '100%',
                maxWidth: '1400px',
                m: 2,
                p: 2,
                justifyContent: 'center',
            }}>
                <Typography
                    sx={{
                        p: 4,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                    variant="h4"
                >
                    Wspólne wartości
                </Typography>
                <Typography
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Button
                        size="large"
                        sx={{
                            m: 2,
                            width: '100%',
                        }}
                        variant='contained'
                        color='inherit'
                        onClick={() => {
                            onGetProducers()
                            onGetCollections(producerId)
                        }}
                    >
                        Odśwież dane
                    </Button>
                </Typography>
                <ProducerComponent
                    producersData={producersData}
                    producerId={producerId}
                    onChangeProducerId={handleChangeProducerId}
                />
                <Divider variant='middle' sx={{ m: 2 }} />
                <CollectionComponent
                    disabled={producerId === ''}
                    collectionId={collectionId}
                    onSetCollectionId={setCollectionId}
                    collectionsData={collectionsData}
                />
                <DetailsComponent
                    name={name}
                    setName={setName}
                    price={price}
                    setPrice={setPrice}
                    crossed={crossed}
                    setCrossed={setCrossed}
                    isPriceVissible={isPriceVissible}
                    setIsPriceVissible={setIsPriceVissible}
                    width={width}
                    setWidth={setWidth}
                    height={height}
                    setHeight={setHeight}
                    depth={depth}
                    setDepth={setDepth}
                    description={description}
                    setDescription={setDescription}
                />
                <Divider variant='middle' sx={{ m: 2 }} />
                <PremisesComponent
                    premises={premises}
                    onSetPremises={handleChangePremiseState}
                />
            </Card>
        </Box>
    )
}

export default CommonValues;