import { Card, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import React from "react";
import { FC } from "react";
import { ICategory, IOpenedCategory } from "../../../../../../../interfaces/ICategory";
import { categoryData } from "../../../../../../Furniture/categoryData";

interface CategoriesComponentProps {
    categoryState: string[] | null,
    onSetCategories: React.Dispatch<React.SetStateAction<string[]>>,
}

const CategoriesComponent: FC<CategoriesComponentProps> = ({
    categoryState,
    onSetCategories,
}) => {

    
    const handleChangeCategory = (categoryItem: string) => {
        if (categoryState?.includes(categoryItem)) {
            handleDeleteSubcategories(categoryItem);
            onSetCategories(prevState => prevState.filter((category: string) => category !== categoryItem));
        } else {
            onSetCategories(prevState => [...prevState, categoryItem]);
        }
    }


    const handleDeleteSubcategories = (categoryItem: string) => {
        const subcategories = categoryData.find((category: ICategory) => category.name === categoryItem)?.subcategories;
        if (!subcategories) return;

        const subcategoryNames = subcategories.map((subcategory: IOpenedCategory) => subcategory.name);
        onSetCategories((prevState: string[]) => prevState.filter((category: string) => !subcategoryNames.includes(category)));
    }


    const handleChangeSubcategory = (categoryItem: string) => {
        if (categoryState?.includes(categoryItem)) {
            onSetCategories(prevState => prevState.filter((category: string) => category !== categoryItem));
        } else {
            onSetCategories(prevState => [...prevState, categoryItem]);
        }
    }


    return (
        <Card sx={{ m: 1, mr: 1, p: 2, width: '50%' }}>
            <Typography variant="h5" component="div">
                Kategorie
            </Typography>
            <FormGroup sx={{ mb: 5 }}>
                {categoryData.map((categoryItem: ICategory) => (
                    <React.Fragment key={categoryItem.name}>
                        <FormControlLabel
                            key={categoryItem.name}
                            label={categoryItem.name}
                            control={
                                <Checkbox
                                    checked={categoryState?.includes(categoryItem.name)}
                                    onChange={() => handleChangeCategory(categoryItem.name)}
                                />
                            }
                        />
                        {(categoryItem.subcategories) &&
                            categoryItem.subcategories.map((subcategory: IOpenedCategory) => {
                                if (!categoryState?.includes(categoryItem.name)
                                    &&
                                    !categoryState?.includes(subcategory.name)
                                ) return null;

                                return (
                                    <FormControlLabel
                                        key={subcategory.name}
                                        sx={{ ml: 2 }}
                                        label={subcategory.name}
                                        control={
                                            <Checkbox
                                                checked={categoryState?.includes(subcategory.name)}
                                                onChange={() => handleChangeSubcategory(subcategory.name)}
                                            />
                                        }
                                    />
                                )
                            })}
                    </React.Fragment>
                ))}
            </FormGroup>
        </Card>
    )
}

export default CategoriesComponent;