import { Card, Box } from "@mui/material";
import { FC } from "react";

interface MainImageComponentProps {
    imageSrc: File;
}

const MainImageComponent: FC<MainImageComponentProps> = ({
    imageSrc,
}) => {
    return (
        <Card sx={{ m: 1, p: 1, width: '20%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <img
                    alt="furniture"
                    style={{
                        width: '230px',
                        padding: '15px',
                        objectFit: 'cover'
                    }}
                    src={URL.createObjectURL(imageSrc)}
                />
            </Box>
        </Card>
    )
}

export default MainImageComponent;