import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, Divider, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";
import { useModal } from "../../hooks/useModal";
import CustomButton from "../_common/CustomButton";


const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  width: 100%;
`


interface FormLayoutProps {
    buttonText?: string,
    children: React.ReactNode,
    title: string,
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
    onClickButton?: () => void,
    onClickClose?: () => void,
}

const FormLayout: FC<FormLayoutProps> = ({
    buttonText,
    children,
    title,
    maxWidth,
    onClickButton,
    onClickClose,
}) => {
    const modal = useModal();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));



    return (
        <Dialog
            open
            fullWidth
            maxWidth={maxWidth || 'xs'}
            fullScreen={fullScreen}
            aria-labelledby="responsive-dialog-title"
            onClose={() => onClickClose ? onClickClose() : modal.close()}
            scroll="paper"
        >
            <DialogContent>
                <>
                    <Container>
                        <Typography variant='h6' sx={{ mt: '2px' }}>
                            {title}
                        </Typography>
                        <IconButton
                            onClick={() => onClickClose ? onClickClose() : modal.close()}
                            color='error'
                        >
                            <Close />
                        </IconButton>
                    </Container>
                    {children}
                </>
            </DialogContent>
            {onClickButton &&
                <>
                    <Divider sx={{ m: 0 }} />
                    <DialogActions>
                        <CustomButton
                            sx={{
                                width: '100%',
                                m: 2
                            }}
                            text={buttonText || 'Zapisz'}
                            onClick={() => onClickButton()}
                        />
                    </DialogActions>
                </>
            }
        </Dialog>
    )
}

export default FormLayout;