import { Button, Card, Divider } from "@mui/material";
import { FC } from "react";
import { getShowroomUrl } from "../../../../../../services/getShowroomUrl";
import styled from "@emotion/styled";
import CustomButton from "../../../../../../components/_common/CustomButton";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../../../../../hooks/useModal";
import EditCollectionForm from "../../../../../../components/forms/furniture/EditCollectionForm";
import emptyImage from '../../../../../../assets/img/emptyImage.png'

const BASE_URL = getShowroomUrl()

const CustomImage = styled('img')`
    margin-top: 20px;
    max-height: 170px;
    object-fit: contain;
`

const MyButton = styled(CustomButton) <{ isInCart?: boolean }>`
    background-color: ${({ isInCart }) => isInCart ? 'grey' : '#283890'};
    width: 100%;
    margin-top: 10px;
    padding: 0 10;
    font-size: 0.74rem;
`

const MyDivider = styled(Divider)`
    margin-top: 15px;
    margin-bottom: 10px;
`

interface CollectionComponentProps {
    id?: string,
    name?: string,
    image?: string,
    producerId?: string,
    furnitureId?: string,
    showEditTools?: boolean,
    onRefreshFurniture: () => void;
}

const CollectionComponent: FC<CollectionComponentProps> = ({
    name,
    image,
    id,
    producerId,
    furnitureId,
    showEditTools,
    onRefreshFurniture,
}) => {
    const navigate = useNavigate()
    const modal = useModal()

    return (
        <>
            {name ?
                <Card sx={{ p: 2, pt: 0, mt: 2 }}>
                    <CustomImage
                        src={`${BASE_URL}${image}`}
                        alt={name}
                        style={{ width: '100%' }}
                    />
                    {showEditTools ?
                        <Button
                            sx={{ width: '100%', mt: 1 }}
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => modal.open(
                                <EditCollectionForm
                                    collectionId={id}
                                    furnitureId={furnitureId}
                                    producerId={producerId}
                                    onRefreshFurniture={onRefreshFurniture}
                                />
                            )}
                        >
                            Edytuj kolekcję
                        </Button>
                        :
                        <MyButton
                            onClick={() => navigate(`/meble?producerId=${producerId}&collectionId=${id}`)}
                            text="Zobacz całą kolekcję"
                        />
                    }
                </Card>
                :
                showEditTools ?
                    <>
                        <MyDivider />
                        <Card sx={{ p: 2, pt: 0, mt: 2 }}>
                            <CustomImage
                                src={emptyImage}
                                alt={name}
                                style={{ width: '100%' }}
                            />
                            <Button
                                sx={{ width: '100%', mt: 1 }}
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={() => modal.open(
                                    <EditCollectionForm
                                        collectionId={''}
                                        furnitureId={furnitureId}
                                        producerId={producerId}
                                        onRefreshFurniture={onRefreshFurniture}
                                    />
                                )}
                            >
                                Edytuj kolekcję
                            </Button>
                        </Card>
                    </>
                    :
                    <></>
            }
        </>
    )
}

export default CollectionComponent;