import { Box, Card, CardActions, CardHeader, CardMedia, IconButton, List } from "@mui/material";
import React from "react";
import { FC, useRef } from "react";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from "@emotion/styled";
import { useToast } from "../../../../../../../hooks/useToast";


const DropZone = styled.div`
    border: 2px dashed #ccc;
    padding: 20px;
    cursor: pointer;
    margin-bottom: 20px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
`;


interface ImagesComponentProps {
    images: any[];
    onSetImages: (images: any) => void;
}

const ImagesComponent: FC<ImagesComponentProps> = ({
    images,
    onSetImages,
}) => {
    const toast = useRef(useToast()).current;
    const fileInputRef = useRef<HTMLInputElement>(null);


    const handleDrop = (e: any) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files) as File[];
        onSetImages((prevState: any) => [...prevState, ...files]);
    };


    const handleDragOver = (e: any) => {
        e.preventDefault();
    };


    const handleFileSelect = (e: any) => {
        const files = Array.from(e.target.files) as File[];
        onSetImages((prevState: File[]) => [...prevState, ...files]);
    };


    const handleRemoveImage = (index: number) => {
        if (images.length === 1) return toast.error('Nie można usunąć ostatniego zdjęcia');
        onSetImages((prevState: File[]) => prevState.filter((_, i) => i !== index));
    };


    const handleMoveImageUp = (index: number) => {
        if (index > 0) {
            onSetImages((prevState: File[]) => {
                const newImages = [...prevState];
                [newImages[index], newImages[index - 1]] = [newImages[index - 1], newImages[index]];
                return newImages;
            });
        }
    };


    const handleMoveImageDown = (index: number) => {
        if (index < images.length - 1) {
            onSetImages((prevState: File[]) => {
                const newImages = [...prevState];
                [newImages[index], newImages[index + 1]] = [newImages[index + 1], newImages[index]];
                return newImages;
            });
        }
    };



    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <List sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    {images.map((item, index) => (
                        <React.Fragment key={index}>
                            <Card
                                sx={{
                                    maxWidth: 345,
                                    ml: 1,
                                    mr: 1,
                                    mb: 2,
                                    p: 1
                                }}
                            >
                                <CardHeader
                                    sx={{ textAlign: 'center' }}
                                    subheader={index === 0 ? `${index + 1} Zdjęcie główne` : index + 1}
                                />
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={URL.createObjectURL(item)}
                                    sx={{ objectFit: 'contain' }}
                                />
                                <CardActions sx={{ justifyContent: 'center' }}>
                                    <IconButton onClick={() => handleMoveImageUp(index)} color="primary">
                                        <ArrowUpwardIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => handleRemoveImage(index)} color="secondary"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleMoveImageDown(index)} color="primary">
                                        <ArrowDownwardIcon />
                                    </IconButton>
                                </CardActions>
                            </Card>
                        </React.Fragment>
                    ))}
                </List>
            </Box>
            <DropZone
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onClick={() => fileInputRef.current?.click()}
            >
                Przeciągnij zdjęcia tutaj lub kliknij
            </DropZone>
            <input
                type="file"
                multiple
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileSelect}
            />
        </Box>
    )
}

export default ImagesComponent;